/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

interface ButtonStyleProps {
  checkBtn?: any,
  width?: any,
  height?: any
}

const ButtonStyled = styled.div<ButtonStyleProps>`
  cursor: pointer;
  column-gap: 8px;
  width: fit-content;
  .btn-container- {
    position: relative;
    &:hover {
      filter: brightness(1.5);
    }
    .background-btn {
    }
  }
  .btn-container-disable {
    position: relative;
    filter: brightness(0.5);
  }

  .ant-spin-dot-item {
    background-color: #4ef076 !important;
  }

  .btn-name {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    font-size: 20px;
    color: white;
    width: 100%;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ height }) => height + 'px'};
    padding: 0 15px;
  }
  .background-btn {
    background: url(${({ checkBtn }) => checkBtn});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: ${({ height }) => height + 'px'};
    min-width: ${({ width }) => width + 'px'};
  }
`

export default ButtonStyled
