import styled from 'styled-components'

const ChooseCancelStyled = styled.div`
  .choose-horse-modal {
    background-color: ${({ theme }) => theme.color.neutral};
    clip-path: polygon(0 0, 100% 0, 100% 100%, 40px 100%, 0 calc(100% - 40px));
    padding: 32px;
    position: relative;
    min-width: 470px;
    max-width: 700px;

    &.head {
      padding-top: 0;
    }

    .close-btn {
      top: 10px;
      right: 10px;
      background-color: ${({ theme }) => theme.color.transparent};
      border: none;
      z-index: 1;
    }

    .header {
      margin-bottom: 16px;
      p {
        text-align: center;
        padding-top: 10px;

        .title {
          display: inline-block;
          width: fit-content;
          color: #4ef076;
          font-size: 24px;
          line-height: 24px;
          text-transform: uppercase;

          &.warning {
            color: #ff7a00;
          }
        }
      }

      .line__under--title {
        display: block;
        width: 95%;
        margin: 0 auto;
        border-bottom: 1px solid #7a83a6;
      }
    }

    .race-fee {
      margin: 34px 0 40px 0;
      max-width: 300px;
      span {
        margin-left: 5px;

        img {
          width: 15px;
          height: 15px;
          margin-top: -3px;
          margin-left: -1px;
        }
      }
    }

    .race-name-container {
      margin-bottom: 16px;

      .line-bottom {
        display: flex;
        width: 100%;
      }
      .race-name {
        font-size: 24px;
        line-height: 24px;
      }
      .title {
        display: block;
        width: fit-content;
        line-height: 30px;
      }
    }

    .race-info-container {
      gap: 32px;
      padding-bottom: 25px;
      border-bottom: 2px solid ${({ theme }) => theme.color.black};

      .race-info-item {
        gap: 8px;

        .race-info-title {
          font-size: 12px;
          line-height: 14px;
        }

        .race-info-content {
          font-size: 16px;
          line-height: 19px;
        }

        img {
          width: 15px;
          height: 15px;
          margin-top: -3px;
          margin-left: 2px;
        }
      }
    }

    .search-horse-container {
      margin: 21px 0 16px 0;
      .no-horse {
        color: #a3a5ab;
      }

      .search-title {
        font-size: 16px;
        line-height: 24px;
      }

      .search-input {
        background-color: ${({ theme }) => theme.color.black};
        opacity: 0.85;
        width: 240px;
      }
    }

    .horse-list-container {
      max-height: 450px;
      .active {
        background: ${({ theme }) => theme.color.primary};
      }
    }

    .horse-list-container::-webkit-scrollbar-track {
      border: 1px solid #000;
      padding: 2px 0;
      background-color: #000;
    }

    .horse-list-container::-webkit-scrollbar {
      width: 5px;
    }

    .horse-list-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2f323e;
      border: 1px solid #000;
    }

    .confirm-horse {
      .message-err {
        white-space: pre-line;
      }
      .confirm-borrow-question {
        font-size: 24px;
        margin-bottom: 8px;
      }
      margin-top: 35px;
      &-btns {
        margin-top: 26px;
        gap: 20px;
        button {
          border: 0;
          background: none;
          padding: 0;
          margin: 0 40px;
        }
      }
      &-title {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
      .text {
        color: white;
        font-size: 20px;
        /* text-align: center; */
        .message-content {
          text-align: left;
          white-space: pre-line;
        }
        input.ant-input {
          font-size: 20px;
        }
        .message-content {
          white-space: pre-line;
        }
      }
      .password-input {
        .ant-input-password {
          background-color: #8d8d8d;
          .ant-input {
            background-color: #8d8d8d;
            color: white;
          }
        }
      }
    }

    .message-error {
      color: #e01621;
      text-align: start;
      padding-top: 8px;
      font-size: 16px;
    }
    .message-error::first-letter {
      text-transform: uppercase;
    }

    .confirm-horse-btns {
      margin-top: 30px;
    }

    .line__under--title {
      display: block;
      width: 95%;
      margin: 0 auto;
      border-bottom: 1px solid #7a83a6;
    }

    .icon_currency {
      display: inline-block;
      margin-left: 1px;
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }

    .image_margin_top {
      margin-top: 5px;
    }
  }
`

export default ChooseCancelStyled
