/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd'
import guildApi from 'apis/guildApi'
import { constants } from 'apps'
import ChooseHorseModalGuildFarm from 'features/Guild/components/ChooseHoreModalGuildFarm'
import ConfirmGuildFarmModal from 'features/Guild/components/ConfirmGuildFarmModal'
import { RaceTable, RequestLoginModal,
  // SwitchBtn 
} from 'features/Race/components'
import { useAppDispatch, useAppSelector,
  useDebounce,
  useToggle, useUpdateEffect } from 'hooks'
import { GUILD_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import { GetRaceListParams, HorseAvailable, RaceClassNumber, RaceFieldType } from 'models'
import { useEffect, useMemo,
  // useRef,
  useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { encodeURI } from 'js-base64'
import { SearchInput } from 'shared'
import SelectCustom from 'shared/SelectCustom'
import { guildRaceHistory } from 'utils/columns'
import { handleAsyncRequest } from 'utils/helper'

import RaceHistoryStyled from './styled'
import { getTooltipInfo } from 'apps/redux/tooltip/action'

export type RaceClassFilter = {
  name: RaceClassNumber | 'All'
  isActive: boolean
}
export type RaceTypeFilter = {
  name: RaceFieldType | 'All'
  isActive: boolean
}

export type ClassFilters = RaceClassFilter[]
export type TypeFilters = RaceTypeFilter[]

const defaultTypeFilters: TypeFilters = [
  {
    name: 'All',
    isActive: true
  },
  {
    name: RaceFieldType.Turf,
    isActive: false
  },
  {
    name: RaceFieldType.Dirt,
    isActive: false
  }
]
const defaultClassFilters: ClassFilters = [
  {
    name: 'All',
    isActive: true
  },
  {
    name: RaceClassNumber.Class1,
    isActive: false
  },
  {
    name: RaceClassNumber.Class2,
    isActive: false
  },
  {
    name: RaceClassNumber.Class3,
    isActive: false
  },
  {
    name: RaceClassNumber.Class4,
    isActive: false
  },
  {
    name: RaceClassNumber.Class5,
    isActive: false
  },
  {
    name: RaceClassNumber.Class6,
    isActive: false
  },
  {
    name: RaceClassNumber.Class7,
    isActive: false
  },
  {
    name: RaceClassNumber.ClassFreeStyle,
    isActive: false
  }
]

interface RaceHistoryProps {
  isInFarm?: boolean
  loading?: boolean
  isHorse?: boolean
}
export default function RaceHistory({ isInFarm, isHorse }: RaceHistoryProps) {
  const myHorseListParams: GetRaceListParams = {
    limit: 20,
    page: 1,
    // sort: 'lendingDate-desc'
  }
  const [api] = notification.useNotification()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const memoizedResultListSelectionColumns = useMemo(() => guildRaceHistory, [])
  const [params, setParams] = useState<GetRaceListParams>(myHorseListParams)
  const [total, setTotal] = useState<number>()
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [listHorse, setListHorse] = useState([])
  // const [isMyHorseOn, toggleIsMyHorseOn] = useToggle(false)
  // const useRefSwitch = useRef<HTMLButtonElement>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const { guild_id, user_id, horse_id } = useParams()
  const [isModalChooseHorseOpen, toggleIsChooseHorseModalOpen] = useToggle(false)
  const [horseDetails, setHorseDetails] = useState<HorseAvailable>()
  const [isModalSpecialRaceOpen, toggleIsModalSpecialRaceOpen] = useToggle(false)
  const [isRefresh, toggleIsRefresh] = useToggle(false)
  const [classSelect, setClassSelect] = useState('Class')
  const [typeFilters, setTypeFilters] = useState('Field Type')

  const currentUser = useAppSelector(state => state.profile)
  const { t } = useTranslation()

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'top',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  useEffect(() => {
    dispatch(getTooltipInfo())
  }, [])

  useEffect(() => {
    if (isInFarm) {
      setTotal(0)
      setListHorse([])
      return
    }
    getRaceHistory()
  }, [params, isInFarm])

  useUpdateEffect(() => {
    // setListHorse([])
    setParams({
      ...params,
      // myHorse: isMyHorseOn,
      page: 1,
      search: searchValue 
    })
  }, [
    // isMyHorseOn,
    debounceSearchValue
  ])

  const getRaceHistory = async () => {
    setIsLoading(true)
    const removeParams = (obj: any) => {
      const result = Object.keys(obj)?.reduce((accumulator: any, key) => {
        if ((obj[key] != null) && (obj[key] != 'All')) {
          accumulator[key] = obj[key];
        }
        return accumulator;
      }, {});
      return result
    }

    const [error, result]: any = await handleAsyncRequest(
      horse_id ? guildApi.getHorseRaceHistory(guild_id ? guild_id : currentUser?.guild_id, horse_id, removeParams({ ...params })) :
        guildApi.getMemberRaceHistory(guild_id ? guild_id : currentUser?.guild_id, user_id ? user_id : currentUser?.id, removeParams({ ...params }))
    )

    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen()
        setIsLoading(false)
        return
      }
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      const formatData = result.data.records.map((record: any) => {
        return {
          ...record,
          horse_name: record.horse_name,
          raceName: record.race_name,
          racing_class_name: record.racing_class,
          field_type: record.field_type,
          horse_gender: record.horse_gender,
          race_position: record.rank_in_race,
          recervedPrize: record.received_prize,
          sub_avatar: record.horse_avatar,
          total_prize: record.total_prize,
          jackpot: {
            position: record.rank_in_race,
            jackpot: record.jackpot,
            jackpot_detail: record.jackpot_detail
          },
          totalPrizeT: record.total_prize,
        }
      })
      setListHorse(params.page > 1 ? [...listHorse, ...formatData] : formatData)
      setTotal(result.data?.total)
    }
    setIsLoading(false)
  }

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const onGetHorseItem = (horse: HorseAvailable) => {
    setHorseDetails(horse)
  }

  useEffect(() => {
    if (isRefresh) {
      toggleIsChooseHorseModalOpen()
      setParams({ ...params, page: 1 })
    }
  }, [isRefresh])

  const handleClassFilterClicked = (item: any) => {
    setParams({
      ...params, page: 1, raceClass: item
    })
    setClassSelect(item)
  }

  const handleFieldTypeClicked = (item: any) => {
    setParams({
      ...params, page: 1, fieldType: item
    })
    setTypeFilters(item)
  }

  const handleRowItemClick = (value: any) => {
    window.open(`/race/detail/${encodeURI(value?.race_id?.toString())}`, '_blank')
  }

  return (
    <RaceHistoryStyled>
      <div className='head'>
        <span>Race History</span>
      </div>
      <div className='guild-farm'>
        <div className='guild-header'>
          {/* <div className='mt-1'>
            <SwitchBtn
              title={t(`${NOTIFICATION_MESSAGE}.myHorse`)}
              isOn={isMyHorseOn}
              handleSwitchBtnClicked={toggleIsMyHorseOn}
              refSwitch={useRefSwitch}
            />
          </div> */}
          <SelectCustom
            dataSelect={defaultClassFilters}
            nameSelect={classSelect}
            onSelected={handleClassFilterClicked}
          />
          <SelectCustom
            dataSelect={defaultTypeFilters}
            nameSelect={typeFilters}
            onSelected={handleFieldTypeClicked}
          />
          <SearchInput
            searchValue={searchValue}
            handleSearchValueChanged={handleSearchValueChanged}
            customClass='search-horse-input'
            placeholder={t(`${GUILD_MESSAGE}.placeholderSearchRaceName`)}
          />
        </div>
        <RaceTable
          columns={memoizedResultListSelectionColumns}
          data={listHorse ?? []}
          raisePage={setParams}
          loader={!isLoading}
          params={params}
          checkLoadingPage={false}
          totalRace={total}
          currentUser={currentUser}
          isRowClickable={isHorse}
          isHorseTable
          onRowClick={handleRowItemClick}
          noDataText={isInFarm ? t(`${GUILD_MESSAGE}.not_join_guild`) : t(`${NOTIFICATION_MESSAGE}.dataNotFound`)}
        />
      </div>
      {isModalChooseHorseOpen && (
        <ChooseHorseModalGuildFarm
          getHorseDetail={onGetHorseItem}
          toggleIsModalSpecialRaceOpen={toggleIsModalSpecialRaceOpen}
          toggleIsModalOpen={toggleIsChooseHorseModalOpen}
          onCloseButtonClick={() => toggleIsChooseHorseModalOpen(false)}
        />
      )}
      {isModalSpecialRaceOpen && (
        <ConfirmGuildFarmModal
          toggleIsRefresh={toggleIsRefresh}
          horse={horseDetails}
          toggleIsModalOpen={toggleIsModalSpecialRaceOpen}
          onCloseButtonClick={() => toggleIsModalSpecialRaceOpen(false)}
        />
      )}
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </RaceHistoryStyled>
  )
}
