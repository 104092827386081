import { APP_MODAL } from 'assets/images'
import styled from 'styled-components'

const ChooseCancelStyled = styled.div`
  .choose-horse-modal {
    width: 100%;
    height: 100vh;
    .confirm-horse {
      background-image: url(${APP_MODAL});
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      width: 355px;
      height: 203px;
      align-items: center;
      display: grid;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .btn-custom {
      position: relative;
      width: 100%;
      /* align-items: center; */
      display: flex;
      justify-content: center;
      margin-top: 12px;
      cursor: pointer;
      .btn-text {
        position: absolute;
        top: 15%;
        color: white;
      }
    }

    .close-btn {
      top: 10px;
      right: 10px;
      background-color: ${({ theme }) => theme.color.transparent};
      border: none;
      z-index: 1;
    }

    .race-fee {
      margin: 34px 0 40px 0;
      max-width: 300px;
      span {
        margin-left: 5px;

        img {
          width: 15px;
          height: 15px;
          margin-top: -3px;
          margin-left: -1px;
        }
      }
    }

    .race-name-container {
      margin-bottom: 16px;

      .line-bottom {
        display: flex;
        width: 100%;
      }
      .race-name {
        font-size: 24px;
        line-height: 24px;
      }

      .title {
        display: block;
        width: fit-content;
      }
    }

    .race-info-container {
      gap: 32px;
      padding-bottom: 25px;
      border-bottom: 2px solid ${({ theme }) => theme.color.black};

      .race-info-item {
        gap: 8px;

        .race-info-title {
          font-size: 12px;
          line-height: 14px;
        }

        .race-info-content {
          font-size: 16px;
          line-height: 19px;
        }

        img {
          width: 15px;
          height: 15px;
          margin-top: -3px;
          margin-left: 2px;
        }
      }
    }

    .search-horse-container {
      margin: 21px 0 16px 0;
      .no-horse {
        color: #a3a5ab;
      }

      .search-title {
        font-size: 16px;
        line-height: 24px;
      }

      .search-input {
        background-color: ${({ theme }) => theme.color.black};
        opacity: 0.85;
        width: 240px;
      }
    }

    .horse-list-container {
      max-height: 450px;
      .active {
        background: ${({ theme }) => theme.color.primary};
      }
    }

    .horse-list-container::-webkit-scrollbar-track {
      border: 1px solid #000;
      padding: 2px 0;
      background-color: #000;
    }

    .horse-list-container::-webkit-scrollbar {
      width: 5px;
    }

    .horse-list-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2f323e;
      border: 1px solid #000;
    }

    .confirm-horse {
      .confirm-borrow-question {
        font-size: 24px;
        margin-bottom: 8px;
      }
      &-btns {
        position: absolute;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        bottom: 20px;
        button {
          border: 0;
          background: none;
          padding: 0;
          margin: 0 40px;
        }
      }
      &-title {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
      .text {
        color: white;
        font-size: 20px;
        text-align: center;
        margin: 0 10px 0 10px;
        .message-content {
          text-align: left;
          white-space: pre-line;
        }
        input.ant-input {
          font-size: 20px;
        }
      }
      .password-input {
        .ant-input-password {
          background-color: #8d8d8d;
          .ant-input {
            background-color: #8d8d8d;
            color: white;
          }
        }
      }
    }

    .message-error {
      color: #e01621;
      text-align: start;
      padding-top: 8px;
      font-size: 18px;
    }
    .message-error::first-letter {
      text-transform: uppercase;
    }
  }
`

export default ChooseCancelStyled
