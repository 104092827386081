/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { ARMOR_ICON, ARROW_BOTTOM, GAME_TOKEN_KUDA, GAME_TOKEN_MERAH, ICON_ADIL, SKIN_ICON } from 'assets/images'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import ToolTipStyled from './styled'
import { VIEW_DOPING } from '../../assets/images'

interface ToolTipProps {
  jackpot: any
  className?: string
  posTooltip?: boolean
  bt?: number
  mgTop?: number
}

export default function ToolTipPrice({ jackpot, posTooltip, bt = 0, mgTop = 4 }: ToolTipProps) {
  const [totalArmor, setTotalArmor] = useState(0)
  const [totalSkin, setTotalSkin] = useState(0)
  const [totalItem, setTotalItem] = useState(0)
  const [totalKuda, setTotalKuda] = useState(0)
  const [totalMerah, setTotalMerah] = useState(0)
  const [totalAdil, setTotalAdil] = useState(0)
  
  useEffect(() => {
    if (jackpot) {
      jackpot?.jackpotReward?.rewards_details?.map((jack: any) => {
        switch (jack?.item_type) {
          case "HEAD":
          case "BODY":
          case "LEG": {
            return setTotalArmor(prev => +prev + +jack?.amount)
          }
          case "SKIN": {
            return setTotalSkin(prev => +prev + +jack?.amount)
          }
          case "BOOSTER": {
            return setTotalItem(prev => +prev + +jack?.amount)
          }
          case "COIN": {
            if (jack?.parent_sub_type === "KUDA_NEW") {
              return setTotalKuda(prev => +prev + +jack?.amount)
            } else if (jack?.parent_sub_type === "MERAH_NEW") {
              return setTotalMerah(prev => +prev + +jack?.amount)
            } else {
              return setTotalAdil(prev => +prev + +jack?.amount)
            }
          }
          default:
            break;
        }
      })
    }
  }, [])
  
  return (
    <ToolTipStyled posTooltip={posTooltip} bt={bt} mgTop={mgTop} >
      <div className='tooltips'>
        <div className='tooltip__contain'>
          <div className='content-name'>
            <div className='title'>Pool Reward</div>
            <div className='content_jackpot color-yellow'>
              { jackpot.poolReward.price }
              <div className='img_pool_reward'>
                <MemoizedLoadImageCommon srcImg={jackpot.poolReward.icon} />
              </div>
            </div>
            <div className='title'>
              {totalArmor || totalSkin || totalItem || totalKuda || totalMerah || totalAdil ? "Jackpot Reward" : ""}
            </div>
            <div className='content_jackpot color-yellow'>
              {totalArmor ? <div className='content_armor color-yellow'>
                <div className='img_armor'>
                  <MemoizedLoadImageCommon srcImg={ARMOR_ICON} />
                </div>
                { 'x' }
                { totalArmor }
              </div> : ""}
              {totalSkin ? <div className='content_armor color-yellow'>
                <div className='img_armor'>
                  <MemoizedLoadImageCommon srcImg={SKIN_ICON} />
                </div>
                { 'x' }
                { totalSkin }
              </div> : ""}
              {totalItem ? <div className='content_armor color-yellow'>
                <div className='img_armor img_doping'>
                  <MemoizedLoadImageCommon srcImg={VIEW_DOPING} />
                </div>
                { 'x' }
                { totalItem }
              </div> : ""}
            </div>
            <div className='content_jackpot color-yellow'>
              {totalKuda ? <div className='content_armor color-yellow'>
                <div className='img_armor'>
                  <MemoizedLoadImageCommon srcImg={GAME_TOKEN_KUDA} />
                </div>
                { '+' }
                { totalKuda }
              </div> : ""}
              {totalMerah ? <div className='content_armor color-yellow'>
                <div className='img_armor'>
                  <MemoizedLoadImageCommon srcImg={GAME_TOKEN_MERAH} />
                </div>
                { '+' }
                { totalMerah }
              </div> : ""}
              {totalAdil ? <div className='content_armor color-yellow'>
                <div className='img_armor'>
                  <MemoizedLoadImageCommon srcImg={ICON_ADIL} />
                </div>
                { '+' }
                { totalAdil }
              </div> : ""}
            </div>
            <div className='contain_arrow'>
              <img src={ARROW_BOTTOM} alt='' className='arrow' />
            </div>
          </div>
        </div>
      </div>
    </ToolTipStyled>
  )
}
