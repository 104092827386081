/* eslint-disable @typescript-eslint/no-explicit-any */
import { CAREER_BIRU_OLD_ICON, CAREER_MERAH_OLD_ICON, GAME_TOKEN_KUDA, GAME_TOKEN_MERAH } from "assets/images"
import { IMG_INFO } from 'assets/images'
import ToolTipPrice from "shared/ToolTipPrice"
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import PriceContainer from "./styled"

interface RaceTypeProps {
  jackpot: any
  price: any
  type: string
  width?: number
  height?: number
  fontSize?: number
  leftText?: number
  topText?: number
  topICInfo?: number
  rigthICInfo?: number
  posTooltip?: boolean
  widthICInfo?: number
  heightICInfo?: number
}

function PrizeColumn({ jackpot, price, type, width = 110, height = 35, fontSize = 17, leftText = 35, topText = 9, topICInfo = 0, rigthICInfo = 3, widthICInfo = 14, heightICInfo = 14, posTooltip = true }: RaceTypeProps) {
  const getRaceType = (jackpot: any, price: any, type: string) => {
    switch (type) {
      case 'BIRUOLD': {
        return getRaceChild(jackpot, CAREER_BIRU_OLD_ICON, price, 0, 2)
      }
      case 'MERAHOLD': {
        return getRaceChild(jackpot, CAREER_MERAH_OLD_ICON, price, 1, 2)
      }        
      case 'KUDA': {
        return getRaceChild(jackpot, GAME_TOKEN_KUDA, price, 1, 3)
      }
        
      case 'MERAH': {
        return getRaceChild(jackpot, GAME_TOKEN_MERAH, price, 1, 3)
      }
      default:
        return type
    }
  }

  const getRaceChild = (jackpot: any, linkImg: any, price: string, bt: number, mgTop: number) => {
    const jackpotRW = jackpot?.jackpot_detail?.find((element: any) => jackpot?.position === element?.position);
    const reqest: any = {
      poolReward: {
        price,
        icon: linkImg
      },
      jackpotReward: jackpotRW
    }
    
    return <span className="contain__img">
        {jackpot?.jackpot && <ToolTipPrice jackpot={reqest} posTooltip={posTooltip} bt={bt} mgTop={mgTop} />}
        <span className="img__text">{ price }</span>
        <div className='img_curency'>
          <MemoizedLoadImageCommon srcImg={linkImg} />
        </div>
        {jackpot?.jackpot && <span className="ic__info">
          <img src={IMG_INFO} alt='' className='info' />
        </span>}
      </span>
  }

  return (
    <PriceContainer
      width={width}
      height={height}
      fontSize={fontSize}
      leftText={leftText}
      topText={topText}
      topICInfo={topICInfo}
      rigthICInfo={rigthICInfo}
      widthICInfo={widthICInfo}
      heightICInfo={heightICInfo}
    >
      {getRaceType(jackpot, price, type)}
    </PriceContainer>
  )
}

export default PrizeColumn
